import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../../components/layout/ContentWrapper';

import PoliciesNavBar from '../../../components/section/policies/PoliciesNavBar';
import PoliciesContentArea from '../../../components/section/policies/PoliciesContentArea';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';
import H4 from '@rotaready/frecl/build/H4';
import Text from "@rotaready/frecl/build/Text";

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

const Page = () => (
  <Body header={header}>
    <SEO
      title="User Terms of Service - Rotaready"
      url="policies/user-terms-of-service"
    />

    <ResponsiveContainer>
      <ContentWrapper>
        <TitleWrapper>
          <H1 uistyle="brand160" text="User Terms of Service" />
        </TitleWrapper>

        <BodyWrapper>
          <PoliciesNavBar />

          <PoliciesContentArea>
            <p><b>Effective date: 21 May 2018</b></p>

            <p>These User Terms of Service (the "<b>User Terms</b>") govern your access and use of our online
              workforce management tools and platform (the "<b>Services</b>"). Please read them carefully. Even
              though you are signing onto an existing Realm, these User Terms apply to you as a user of the
              Services.</p>

            <H2 uistyle="brand160">First Things First</H2>

            <H4 uistyle="brand160">These User Terms are Legally Binding</H4>

            <p>These User Terms are a legally binding contract between you and us. As part of these User Terms, you
              agree to comply with the most recent version of our <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>, which is incorporated by
              reference into these User Terms. If you access or use the Services, or continue accessing or using
              the Services after being notified of a change to the User Terms or the <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>, you
              confirm that you have read, understand and agree to be bound by the User Terms and the <Link to="/policies/acceptable-use-policy">Acceptable
                Use Policy</Link>. "We", "our" and "us" currently refers to the applicable Rotaready entity in the Contract
              (defined below).</p>

            <H2 uistyle="brand160">Customer's Choices and Instructions</H2>

            <H4 uistyle="brand160">You are an Authorised User on a Realm Controlled by a "Customer"</H4>

            <p>An organisation or other third party that we refer to in these User Terms as "<b>Customer</b>" has invited
              you to a realm (i.e., a unique domain where a group of users may access the Services, as further
              described in our Knowledge Base). If you are joining one of your employer's realms, for example,
              Customer is your employer.</p>

            <H4 uistyle="brand160">What This Means for You - and for Us</H4>

            <p>Customer has separately agreed to our Customer Terms of Service or entered into a written agreement
              with us (in either case, the "<b>Contract</b>") that permitted Customer to create and configure a realm so
              that you and others could join (each invitee granted access to the Services, including you, is an
              "<b>Authorised User</b>"). The Contract contains our commitment to deliver the Services to Customer, who
              may then invite Authorised Users to join its realm(s). When an Authorised User (including, you)
              submits content or information to the Services, such as messages or files ("<b>Customer Data</b>"), you
              acknowledge and agree that the Customer Data is owned by Customer and the Contract provides Customer
              with many choices and control over that Customer Data. For example, Customer may provision or
              deprovision access to the Services, enable or disable third party integrations, manage permissions,
              retention and export settings, and these choices and instructions may result in the access, use,
              disclosure, modification or deletion of certain or all Customer Data. Please check out our Knowledge
              Base pages for more detail on our different Services and the options available to Customer.</p>

            <H4 uistyle="brand160">The Relationship Between You, Customer and Us</H4>

            <p>AS BETWEEN US AND CUSTOMER, YOU AGREE THAT IT IS SOLELY CUSTOMER'S RESPONSIBILITY TO (A) INFORM YOU
              AND ANY AUTHORISED USERS OF ANY RELEVANT CUSTOMER POLICIES AND PRACTICES AND ANY SETTINGS THAT MAY
              IMPACT THE PROCESSING OF CUSTOMER DATA; (B) OBTAIN ANY RIGHTS, PERMISSIONS OR CONSENTS FROM YOU AND
              ANY AUTHORISED USERS THAT ARE NECESSARY FOR THE LAWFUL USE OF CUSTOMER DATA AND THE OPERATION OF THE
              SERVICES; (C) ENSURE THAT THE TRANSFER AND PROCESSING OF CUSTOMER DATA UNDER THE CONTRACT IS LAWFUL;
              AND (D) RESPOND TO AND RESOLVE ANY DISPUTE WITH YOU AND ANY AUTHORISED USER RELATING TO OR BASED ON
              CUSTOMER DATA, THE SERVICES OR CUSTOMER'S FAILURE TO FULFILL THESE OBLIGATIONS. ROTAREADY MAKES NO
              REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, TO YOU RELATING TO THE
              SERVICES, WHICH ARE PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS.</p>

            <H2 uistyle="brand160">A Few Ground Rules</H2>

            <H4 uistyle="brand160">You Must be Over the Legal Age</H4>

            <p>To the extent prohibited by applicable law, the Services are not intended for and should not be used
              by anyone under the age of thirteen. You represent that you are over the legal age and are the
              intended recipient of Customer's invitation to the Services. You may not access or use the Services
              for any purpose if either of the representations in the preceding sentence is not true. Without
              limiting the foregoing, you must be of legal working age.</p>

            <H4 uistyle="brand160">While You Are Here, You Must Follow the Rules</H4>

            <p>To help ensure a safe and productive work environment, all Authorised Users must comply with our <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link> and remain vigilant in reporting inappropriate behavior or content to Customer
              and us.</p>

            <H4 uistyle="brand160">You Are Here At the Pleasure of Customer (and Us)</H4>

            <p>These User Terms remain effective until Customer's subscription for you expires or terminates, or
              your access to the Services has been terminated by Customer or us. Please contact Customer if you at
              any time or for any reason wish to terminate your account, including due to a disagreement with any
              updates to these User Terms or the <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>.</p>

            <H2 uistyle="brand160">Pass-Through Terms</H2>

            <p>To display geographical data, Rotaready uses embedded Google Maps. By virtue of this you agree to be
              bound by the <a href="https://maps.google.com/help/terms_maps.html" target="_blank" rel="noopener noreferrer">Google Maps/Google Earth Additional Terms of Service</a> and <a href="https://www.google.com/intl/ALL/policies/privacy/index.html" target="_blank" rel="noopener noreferrer">Google Privacy Policy</a> which are herein incorporated by reference.</p>

            <H2 uistyle="brand160">Limitation of Liability</H2>

            <p>If we believe that there is a violation of the Contract, User Terms, the Acceptable Use Policy, or
              any of our other policies that can simply be remedied by Customer's removal of certain Customer Data
              or taking other action, we will, in most cases, ask Customer to take action rather than intervene.
              We may directly step in and take what we determine to be appropriate action (including disabling
              your account) if Customer does not take appropriate action or we believe there is a credible risk of
              harm to us, the Services, Authorised Users, or any third parties. IN NO EVENT WILL YOU OR WE HAVE
              ANY LIABILITY TO THE OTHER FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL,
              INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR
              UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. UNLESS YOU ARE ALSO A CUSTOMER (AND WITHOUT LIMITATION TO OUR RIGHTS
              AND REMEDIES UNDER THE CONTRACT), YOU WILL HAVE NO FINANCIAL LIABILITY TO US FOR A BREACH OF THESE
              USER TERMS. OUR MAXIMUM AGGREGATE LIABILITY TO YOU FOR ANY BREACH OF THE USER TERMS IS ONE HUNDRED
              POUNDS (£100) IN THE AGGREGATE. THE FOREGOING DISCLAIMERS WILL NOT APPLY TO THE EXTENT PROHIBITED BY
              APPLICABLE LAW AND DO NOT LIMIT EITHER PARTY'S RIGHT TO SEEK AND OBTAIN EQUITABLE RELIEF.</p>

            <H4 uistyle="brand160">Application of Consumer Law</H4>

            <p>Rotaready is a workplace tool intended for use by businesses and organisations and not for consumer
              purposes. To the maximum extent permitted by law, you hereby acknowledge and agree that consumer
              laws do not apply. If however any consumer laws (e.g., in Australia, the Competition and Consumer
              Act 2010 (Cth)) do apply and cannot otherwise be lawfully excluded, nothing in these User Terms will
              restrict, exclude or modify any statutory warranties, guarantees, rights or remedies you have, and
              our liability is limited (at our option) to the replacement, repair or resupply of the Services or
              the pro-rata refund to Customer of pre-paid fees for your subscription covering the remainder of the
              term.</p>

            <H4 uistyle="brand160">Survival</H4>

            <p>The sections titled "The Relationship Between You, Customer, and Us," "Limitation of Liability," and
              "Survival," and all of the provisions under the general heading "General Provisions" will survive
              any termination or expiration of the User Terms.</p>

            <H2 uistyle="brand160">General Provisions</H2>

            <H4 uistyle="brand160">Email and Rotaready Messages</H4>

            <p>Except as otherwise set forth herein, all notices under the User Terms will be by email, although we
              may instead choose to provide notice to Authorised Users through the Services (e.g., a
              notification). Notices to Rotaready should be sent to <a href="mailto:team@rotaready.com">team@rotaready.com</a>. A notice will be deemed to
              have been duly given (a) the day after it is sent, in the case of a notice sent through email; and
              (b) the same day, in the case of a notice sent through the Services. Notices under the Contract will
              be delivered solely to Customer in accordance with the terms of that agreement.</p>

            <H4 uistyle="brand160">Privacy Policy</H4>

            <p>Please review our <Link to="/policies/privacy-policy">Privacy Policy</Link> for more information on how
              we collect and use data relating to the use and performance of our products.</p>

            <H4 uistyle="brand160">Modifications</H4>

            <p>As our business evolves, we may change these User Terms or the <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>. If we make a
              material change to the User Terms or the <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>, we will provide you with reasonable
              notice prior to the change taking effect either by emailing the email address associated with your
              account or by messaging you through the Services. You can review the most current version of the
              User Terms at any time by visiting this page, and by visiting the following for the most current
              versions of the other pages that are referenced in these User Terms: <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link> and <Link to="/policies/privacy-policy">Privacy Policy</Link>. Any material revisions to these User Terms will become effective on the date set
              forth in our notice, and all other changes will become effective on the date we publish the change.
              If you use the Services after the effective date of any changes, that use will constitute your
              acceptance of the revised terms and conditions.</p>

            <H4 uistyle="brand160">Waiver</H4>

            <p>No failure or delay by either party in exercising any right under the User Terms, including the <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>, will constitute a waiver of that right. No waiver under the User Terms will
              be effective unless made in writing and signed by an authorised representative of the party being
              deemed to have granted the waiver.</p>

            <H4 uistyle="brand160">Severability</H4>

            <p>The User Terms, including the <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>, will be enforced to the fullest extent permitted
              under applicable law. If any provision of the User Terms is held by a court of competent
              jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so
              as best to accomplish the objectives of the original provision to the fullest extent permitted by
              law, and the remaining provisions of the User Terms will remain in effect.</p>

            <H4 uistyle="brand160">Assignment</H4>

            <p>You may not assign any of your rights or delegate your obligations under these User Terms, including
              the <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>, whether by operation of law or otherwise, without the prior written
              consent of us (not to be unreasonably withheld). We may assign these User Terms in their entirety
              (including all terms and conditions incorporated herein by reference), without your consent, to a
              corporate affiliate or in connection with a merger, acquisition, corporate reorganisation, or sale
              of all or substantially all of our assets.</p>

            <H4 uistyle="brand160">Governing Law; Venue; Waiver of Jury Trial; Fees</H4>

            <p>The User Terms, including the <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>, and any disputes arising out of or related
              hereto, will be governed exclusively by the same applicable governing law of the Contract, without
              regard to conflicts of laws rules or the United Nations Convention on the International Sale of
              Goods. The courts located in the applicable venue of the Contract will have exclusive jurisdiction
              to adjudicate any dispute arising out of or relating to the User Terms, including the <Link to="/policies/acceptable-use-policy">Acceptable Use
                Policy</Link>, or its formation, interpretation or enforcement. Each party hereby consents and submits to
              the exclusive jurisdiction of such courts. Each party also hereby waives any right to jury trial in
              connection with any action or litigation in any way arising out of or related to the Contract. In
              any action or proceeding to enforce rights under the User Terms, the prevailing party will be
              entitled to recover its reasonable costs and attorney's fees.</p>

            <p>Each party hereby consents and submits to the exclusive jurisdiction of such courts. Each party also
              hereby waives any right to jury trial in connection with any action or litigation in any way arising
              out of or related to the User Terms. In any action or proceeding to enforce rights under the User
              Terms, the prevailing party will be entitled to recover its reasonable costs and attorney's
              fees.</p>

            <H4 uistyle="brand160">Entire Agreement</H4>

            <p>The User Terms, including any terms incorporated by reference into the User Terms, constitute the
              entire agreement between you and us and supersede all prior and contemporaneous agreements,
              proposals or representations, written or oral, concerning its subject matter. To the extent of any
              conflict or inconsistency between the provisions in these User Terms and any pages referenced in
              these User Terms, the terms of these User Terms will first prevail; provided, however, that if there
              is a conflict or inconsistency between the Contract and the User Terms, the terms of the Contract
              will first prevail, followed by the provisions in these User Terms, and then followed by the pages
              referenced in these User Terms (e.g., the Privacy Policy). Customer will be responsible for
              notifying Authorised Users of those conflicts or inconsistencies and until such time the terms set
              forth herein will be binding.</p>

            <H2 uistyle="brand160">Contacting Rotaready</H2>

            <p>Please also feel free to contact Rotaready if you have any questions about these User Terms of
              Service. You may contact us at <a href="mailto:team@rotaready.com">team@rotaready.com</a> or at our mailing address below:</p>

            <p>
              Rotaready Ltd
              <br />
              1 Hudson's Place
              <br />
              Victoria Station
              <br />
              London
              <br />
              SW1V 1JT
              <br />
              United Kingdom
            </p>
          </PoliciesContentArea>
        </BodyWrapper>
      </ContentWrapper>
    </ResponsiveContainer>
  </Body>
);

export default Page;
